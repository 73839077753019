<template>
  <div v-if="property">
    <CRow class="auto pb-4">
      <CCol>
        <div class="subheader">
          {{ property.name }}
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <h6>Post Update ID: {{ property.post_update_id }}</h6>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <h6>Property Status: {{ property.status }}</h6>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RTableFilterOptions :config="filterConfig" @change="handleFilterChange" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RAsyncPagedTable
          :data="data.results"
          :columns="columns"
          :page="page"
          :page-size="pageSize"
          :total-records="data.count"
          @fetch="fetchPropertySummaryReport"
          @page-change="onPageChange"
        >
          <template #id="{ item }">
            <td>
              <CLink @click="viewTaskDetailAside(item)">
                {{ item.id }}
              </CLink>
            </td>
          </template>
          <template #created="{ item }">
            <td>
              <div class="heavy">
                {{ item.created | formatDateTime('MMM dd, yyyy') }}
              </div>
              <div class="faded">
                {{ item.created | formatDateTime('h:mm a') }}
              </div>
            </td>
          </template>
          <template #completed="{ item }">
            <td>
              <div class="heavy">
                {{ item.completed | formatDateTime('MMM dd, yyyy') }}
              </div>
              <div class="faded">
                {{ item.completed | formatDateTime('h:mm a') }}
              </div>
            </td>
          </template>
          <template #state="{ item }">
            <td>
              <template v-if="item.state === 'failed'">
                <span class="color-danger">{{ item.state }}</span>
              </template>
              <template v-else>
                <span>{{ item.state }}</span>
              </template>
            </td>
          </template>
          <template #floorplan="{ item }">
            <td>
              {{ item.floorplan_name || item.floorplan_id }}
            </td>
          </template>
          <template #unit="{ item }">
            <td>
              {{ item.unit_name || item.unit_id }}
            </td>
          </template>
          <template #old_price="{ item }">
            <td>
              ${{ parseInt(item.data.old.price) }}
            </td>
          </template>
          <template #new_price="{ item }">
            <td>
              ${{ parseInt(item.data.fields.price) }}
            </td>
          </template>
          <template #posted_id="{ item }">
            <td>
              <template v-if="item.view_url">
                <a target="_blank" :href="item.view_url">{{ item.data.posted_id }}</a>
              </template>
              <template v-else>
                {{ item.data.posted_id }}
              </template>
            </td>
          </template>
        </RAsyncPagedTable>
      </CCol>
    </CRow>
    <PriceUpdatingTaskAside
      :show.sync="showAside"
      :task="viewTarget"
    />
  </div>
  <div v-else-if="!loading">
    Sorry, that property doesn't seem to exist.
  </div>
</template>

<script>
/**
 * PriceUpdatingPropertyReport
 *
 * Displays a list of Price Updating tasks for a property.
 *
 * Events:
 *
 * @fetch: refresh table data
 * @page-change: triggered when the selected page or pageSize are changed
 */
import { formatISO } from 'date-fns'

import { RAsyncPagedTable, RTableFilterOptions } from '@/components/tables'
import { CraigslistAPI, ReportingAPI } from '@/services/api/resources'
import constants from '@/utils/constants'

import PriceUpdatingTaskAside from './_components/PriceUpdatingTaskAside'

const columns = [
  {
    key: 'id',
    label: 'Task ID',
    _style: 'width: 5%'
  },
  {
    key: 'created',
    label: 'Created',
    _style: 'width: 10%'
  },
  {
    key: 'completed',
    label: 'Completed',
    _style: 'width: 10%'
  },
  {
    key: 'state',
    label: 'State',
    _style: 'width: 10%'
  },
  {
    key: 'failure_reason',
    label: 'Message',
    _style: 'width: 15%'
  },
  {
    key: 'floorplan',
    label: 'Floorplan',
    _style: 'width: 10%'
  },
  {
    key: 'unit',
    label: 'Unit',
    _style: 'width: 10%'
  },
  {
    key: 'old_price',
    label: 'Old Price',
    _style: 'width: 10%'
  },
  {
    key: 'new_price',
    label: 'New Price',
    _style: 'width: 10%'
  },
  {
    key: 'posted_id',
    label: 'CL Ad ID',
    _style: 'width: 10%'
  }
]
export default {
  name: 'PropertyReport',
  components: {
    PriceUpdatingTaskAside,
    RAsyncPagedTable,
    RTableFilterOptions
  },
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      property: {
        name: '',
        post_update_id: '',
        status: ''
      },
      data: {
        results: [],
        count: 0,
        page: this.page,
        page_size: this.pageSize
      },
      page: 1,
      pageSize: 25,
      viewTarget: null,
      showAside: false,
      filters: {
        state: [],
        failureReason: [],
        search: ''
      },
      filterConfig: {
        state: {
          type: 'multiselect',
          label: 'State',
          options: constants.rooof.Task.STATES
        },
        failureReason: {
          type: 'multiselect',
          label: 'Message',
          options: constants.rooof.Task.FAILURE_REASONS
        },
        search: {
          type: 'input',
          label: 'Search'
        }
      }
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.page = 1
        this.fetchPropertySummaryReport(this.$route.params.pid)
      }
    }
  },
  async created () {
    this.columns = columns
    this.page = 1
    await this.fetchPropertySummaryReport(this.$route.params.pid)
  },
  methods: {
    /**
     * Fetch the Price Updating summary report for this property.
     *
     * @param {Number} id - property id to fetch
     */
    async fetchPropertySummaryReport (id) {
      this.$emit('loading', true)
      const params = new URLSearchParams()
      params.append('from_date', formatISO(this.dateRange.start))
      params.append('to_date', formatISO(this.dateRange.end))
      params.append('tz', this.dateRange.tz)
      params.append('page', this.page)
      params.append('page_size', this.pageSize)

      this.filters.state.forEach(value => params.append('state', value))
      this.filters.failureReason.forEach(value => params.append('failure_reason', value))
      if (this.filters.search) {
        params.append('q', this.filters.search)
      }
      // Get Price Updating Tasks for the property
      const response = await ReportingAPI.priceUpdating.propertySummary({ id: id, params })
      if (response) {
        this.data = response.data

        const propertyResponse = await CraigslistAPI.properties.retrieve({ id })
        if (propertyResponse) {
          this.property = propertyResponse.data.property
          this.$store.commit('set', ['selectedProperty', this.property])
        }
      }
      this.$emit('loading', false)
    },
    /**
     * Fetch the Price Updating summary report when the page changes
     *
     * @param {Number} pageData
     */
    onPageChange (pageData) {
      this.page = pageData.page
      this.pageSize = pageData.pageSize
      this.fetchPropertySummaryReport(this.$route.params.pid)
    },
    /**
     * Update search filters for tasks
     */
    handleFilterChange (filters) {
      this.filters = filters
      this.fetchPropertySummaryReport(this.$route.params.pid)
    },
    /**
     * Display the task detail aside
     *
     * @param {Object} row
     */
    viewTaskDetailAside (row) {
      this.viewTarget = row
      this.showAside = true
    }
  }
}
</script>

<style lang="scss" scoped>
.subheader {
  font-size: 1.2rem;
  color: $gray-600;
}
.color-danger {
  color: $danger;
}
</style>
