var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.property)?_c('div',[_c('CRow',{staticClass:"auto pb-4"},[_c('CCol',[_c('div',{staticClass:"subheader"},[_vm._v(" "+_vm._s(_vm.property.name)+" ")])])],1),_c('CRow',[_c('CCol',[_c('h6',[_vm._v("Post Update ID: "+_vm._s(_vm.property.post_update_id))])])],1),_c('CRow',[_c('CCol',[_c('h6',[_vm._v("Property Status: "+_vm._s(_vm.property.status))])])],1),_c('CRow',[_c('CCol',[_c('RTableFilterOptions',{attrs:{"config":_vm.filterConfig},on:{"change":_vm.handleFilterChange}})],1)],1),_c('CRow',[_c('CCol',[_c('RAsyncPagedTable',{attrs:{"data":_vm.data.results,"columns":_vm.columns,"page":_vm.page,"page-size":_vm.pageSize,"total-records":_vm.data.count},on:{"fetch":_vm.fetchPropertySummaryReport,"page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{on:{"click":function($event){return _vm.viewTaskDetailAside(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"heavy"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created,'MMM dd, yyyy'))+" ")]),_c('div',{staticClass:"faded"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created,'h:mm a'))+" ")])])]}},{key:"completed",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"heavy"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.completed,'MMM dd, yyyy'))+" ")]),_c('div',{staticClass:"faded"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.completed,'h:mm a'))+" ")])])]}},{key:"state",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.state === 'failed')?[_c('span',{staticClass:"color-danger"},[_vm._v(_vm._s(item.state))])]:[_c('span',[_vm._v(_vm._s(item.state))])]],2)]}},{key:"floorplan",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.floorplan_name || item.floorplan_id)+" ")])]}},{key:"unit",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.unit_name || item.unit_id)+" ")])]}},{key:"old_price",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" $"+_vm._s(parseInt(item.data.old.price))+" ")])]}},{key:"new_price",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" $"+_vm._s(parseInt(item.data.fields.price))+" ")])]}},{key:"posted_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.view_url)?[_c('a',{attrs:{"target":"_blank","href":item.view_url}},[_vm._v(_vm._s(item.data.posted_id))])]:[_vm._v(" "+_vm._s(item.data.posted_id)+" ")]],2)]}}],null,false,704825342)})],1)],1),_c('PriceUpdatingTaskAside',{attrs:{"show":_vm.showAside,"task":_vm.viewTarget},on:{"update:show":function($event){_vm.showAside=$event}}})],1):(!_vm.loading)?_c('div',[_vm._v(" Sorry, that property doesn't seem to exist. ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }