<template>
  <CSidebar
    v-if="task"
    aside
    overlaid
    :show="show"
    color-scheme="light"
    size="xl"
    class="sidebar"
    @update:show="$emit('update:show', $event)"
  >
    <CSidebarClose @click.native="$emit('update:show', false)" />
    <div class="p-4">
      <h4>Price Updater Task</h4>
      <h6>{{ task.id }}</h6>
      <hr>
      <div class="pl-2">
        <div v-for="item in taskData" :key="item.key" class="pb-2">
          <div class="faded">
            {{ item.label }}
          </div>
          <div v-if="item.value" class="heavy">
            {{ item.value }}
          </div>
          <div v-else>
            -
          </div>
        </div>
        <div class="faded">
          Craigslist Ad ID
        </div>
        <div v-if="task.view_url" class="heavy pb-2" color="info">
          <a target="_blank" :href="task.view_url">{{ task.data.posted_id }}</a>
        </div>
        <div v-else class="heavy pb-2">
          {{ task.data.posted_id }}
        </div>
        <div class="faded">
          Data
        </div>
        <CCard color="light">
          <CCardBody>
            <pre>{{ formatJsonData }}</pre>
          </CCardBody>
        </CCard>
      </div>
    </div>
  </CSidebar>
</template>

<script>
import { formatDate } from '@/utils'

export default {
  name: 'PriceUpdatingTaskAside',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    task: {
      type: Object,
      default: null
    }
  },
  computed: {
    taskData () {
      return [
        { label: 'Created', value: this.formatDate(this.task.created) },
        { label: 'Completed', value: this.formatDate(this.task.completed) },
        { label: 'State', value: this.task.state },
        { label: 'Failure Message', value: this.task.failure_reason },
        { label: 'Floorplan', value: (this.task.floorplan_name || this.task.floorplan_id) },
        { label: 'Unit', value: (this.task.unit_name || this.task.unit_id) },
        { label: 'Old Price', value: parseInt(this.task.data.old.price) },
        { label: 'New Price', value: parseInt(this.task.data.fields.price) },
        { label: 'Ad ID', value: this.task.ad }
      ]
    },
    formatJsonData () {
      return JSON.stringify(this.task.data, undefined, 2)
    }
  },
  methods: {
    /**
     * Returns a formatted date and time
     *
     * @param value: timestamp
     * @returns {String}
     */
    formatDate (value) {
      if (!value) {
        return ''
      }
      return formatDate(new Date(value), 'MMM dd, yyyy, h:mm a')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
